<template>
  <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableImpr"
        title="항목별 개선목록"
        :columns="gridImpr.columns"
        :editable="editable && !disabled"
        :data="data.safetyCheckImproveModelList"
        :merge="gridImpr.merge"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip outline square
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              :color="item.ibmClassCd === 'IC00000001' ? 'blue-6' : 'orange'"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <div v-if="false">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="!disabled"
                icon="add"
                color="blue-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props, '1')">
                <q-tooltip>
                  개선요청등록
                </q-tooltip>
              </q-btn>
              <q-btn
                v-if="!disabled"
                icon="add"
                color="orange"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props, '2')">
                <q-tooltip>
                  즉시조치등록
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'safety-check-impr',
  props: {
    data: {
      type: Object,
      default: () => ({
        safetyCheckId: '',  // 열화점검 일련 번호
        checkStatusCd: '',
        safetyCheckName: '',
        safetyCheckResultModelList: [], // sheet
        safetyCheckImproveModelList: [], 
      }),
    },
  },
  data() {
    return {
      editable: true,
      gridImpr: {
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:30%',
            sortable: false,
            fix: true,
          },
          {
            name: 'checkItemCycleName',
            field: 'checkItemCycleName',
            label: '주기',
            align: 'center',
            style: 'width:10%',
            sortable: false,
            fix: true,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:60%',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '200px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      prompt: false,
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: props.row.checkItemName,
          relationTableKey: props.row.safetyCheckResultId+"/"+this.data.safetyCheckId,
          ibmTaskTypeCd: 'ITT0000075'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: props.row.checkItemName,
          relationTableKey: props.row.safetyCheckResultId+"/"+this.data.safetyCheckId,
          ibmTaskTypeCd: 'ITT0000075'
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$emit('getDetail');
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail');
    },
    chipDatas(row) {
      let returnData = [];
      let splitId = this.$_.split(row['sopImprovementIds'], ',');
      let splitStep = this.$_.split(row['ibmStepNames'], ',');
      let splitClass = this.$_.split(row['ibmClassCds'], ',');
      let improveContent = this.$_.split(row['improveContents'], ',');
      let actionDeptName = this.$_.split(row['actionDeptNames'], ',');
      let actionCompleteRequestDate = this.$_.split(row['actionCompleteRequestDates'], ',');

      if (splitId.length === splitStep.length) {
        let i = 0;
        this.$_.forEach(splitId, item => {
          let color = this.$_.find(this.colorItems, { stepperMstNm: splitStep[i] });
          let splitContent = '';
          if (improveContent[i].length > 5) {
            splitContent = improveContent[i].substring(0, 5) + '...';
          } else {
            splitContent = improveContent[i];
          }
          returnData.push({
            title: splitClass[i] === 'IC00000001' ? splitContent + ' / ' + splitStep[i] : splitContent + ' / 즉시조치',
            color: color ? color.colorClass : '',
            sopImprovementId: item,
            ibmClassCd: splitClass[i] === 'IC00000001' ? 'IC00000001' : 'IC00000005',
            improveContent: improveContent[i],
            actionDeptName: actionDeptName[i],
            actionCompleteRequestDate: actionCompleteRequestDate[i],
          });
          i++;
        });
      }
      return returnData;
    },
  }
};
</script>